import { Injectable } from '@angular/core';

@Injectable()
export class PeriodsService {
  getYears() {
    return [
      '2021', '2022', '2023',
      '2024', '2025', '2026',
      '2027', '2028', '2029',
    ];
  }

  getMonths() {
    return [
      'Ocak', 'Şubat', 'Mart',
      'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül',
      'Ekim', 'Kasım', 'Aralık',
    ];
  }

  getWeeks() {
    return [
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
      'Pazar',
    ];
  }
}
