import {Component, Input} from '@angular/core';
import {environment} from 'environments/environment.prod';
import {FormControl} from '@angular/forms';
import {MediaUplaodService} from "../../../@core/utils/media-uplaod.service";
import {IResponseBody} from "../../http.interface";
import {IUploadResponse} from "../../interfaces/article.interface";

@Component({
  selector: 'ngx-text-editor',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent {
    @Input()
    formControl!: FormControl;

    tinyMceSettings: any = {
        height: 500,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
            'undo redo | formatselect | bold italic | \
            underline strikethrough | \
            blockquote | \
            bullist numlist | \
            link image media | \
            forecolor backcolor | \
            alignleft aligncenter alignright alignjustify | \
            removeformat help |',
        block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6',
        images_upload_handler: (blobInfo, success) => {
            const file = blobInfo.blob();
            return this.mediaUploadService.upload(file).toPromise()
                .then((uploadReq) => uploadReq.toPromise())
                .then((response: IResponseBody<IUploadResponse>) => {
                    success(environment.cdnPath  + response.data.fileId);
                });
        }
    };
    public tinyApiKey = environment.editorApiKey;

    constructor(
        private mediaUploadService: MediaUplaodService,
    ) {
    }
}
