import { Injectable }               from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
}                                   from '@angular/common/http';
import { Observable }               from 'rxjs';
import { getEndpoint, environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    
    constructor() { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let accessToken: string = localStorage.getItem('accessToken') || '';
        accessToken = accessToken ? 'Bearer ' + accessToken : '';
        
        if(request.method !== 'PUT') {
            request = request.clone({
                setHeaders: {
                    Authorization: accessToken,
                    'api-key': environment.apiKey
                }
            });
        }
        
        return next.handle(request);
    }
}
