import { HttpErrorResponse }                                   from '@angular/common/http';
import { Component, OnInit }                                   from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router }                                              from '@angular/router';
import { LoginService }                                        from '../../@core/utils/login.service';
import { IResponseBody }                                       from '../../@theme/http.interface';
import { NbToastrService } from '@nebular/theme';


@Component({
    selector   : 'ngx-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls  : [ './forgot-password.component.scss' ]
})
export class ForgotPasswordComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private toastrService: NbToastrService
    ) { }

    ngOnInit(): void {
        this.fnCreateLoginForm();
        this.loginService.logout();
    }

    public fnCreateLoginForm() {
        this.form = this.formBuilder.group({
            email   : [ '', [Validators.required, Validators.email] ],
        });
    }

    public fnGetFormControl(controlName: string[]): AbstractControl {
        return this.form.get(controlName) as AbstractControl;
    }

    public fnGetFormControlErrors(controlName: string[], errorName: string): boolean {
        const formControl: AbstractControl = this.fnGetFormControl(controlName);
        if(!controlName) {
            return false;
        }
        return formControl.hasError(errorName) && (formControl.dirty || formControl.touched);
    }

    public fnClickSubmitButton() {
        if(this.form.invalid) return;

        this.loginService.forgotPassword(this.form.value)
            .subscribe((response: IResponseBody<any>) => {
                    this.toastrService.success(
                        'The password reset link has been sent to your e-mail. Please check your e-mail.',
                        'Forgot Password',
                        {duration: 5000}
                    )
                },
                (error: HttpErrorResponse) => {
                    this.toastrService.danger( error.message,'Fail');
                }
            )
    }

}
