import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError }        from 'rxjs/operators';
import { IResponseBody }          from '../../@theme/http.interface';
import { IAdminLoginResponse, IAdmin } from '../../@theme/interfaces/admin.interface';
import { LoginService }                from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private loginService: LoginService,
        private router: Router
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.loginService.check().pipe(
            map((response: IResponseBody<IAdmin> ) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                return !!response.data._id;
            }),
            catchError((error) => {
                this.router.navigate(['/auth/login']);
                return throwError(error);
            })
        );
  }

}
