<div class="title mb-5">
	<img class="logo-img" src="../../../assets/images/logo.svg" alt="Kevin Jubbal logo">
</div>

<div class="row" [formGroup]="form">
	<!-- ADD PRODUCT -->
	<div class="col-md-12">
		<nb-card>
			<nb-card-body class="example-items-col">
				<!-- MAIL -->
				<input
					type="text"
					nbInput fullWidth placeholder="Email"
					class="form-control"
					id="E-mail"
					formControlName="email">
				<span class="text-danger"
					  *ngIf="fnGetFormControlErrors(['email'], 'required')">
                               Email address cannot be left blank!
                            </span>
				<!-- E:MAIL -->

				<!-- PASSWORD -->
				<input type="password" nbInput fullWidth placeholder="Password" formControlName="password"
					   id="password">
				<span class="text-danger" *ngIf="fnGetFormControlErrors(['password'], 'required')">
					   Password cannot be left blank!
				</span>
				<!-- PASSWORD -->

                <a routerLink="/auth/forgot-password" class="d-inline-block mt-3">Forgot Password</a>

				<button nbButton
						fullWidth
						size="medium"
						status="info"
						[disabled]="!form.valid"
						(click)="fnClickSubmitButton()">
					Login
				</button>

			</nb-card-body>
		</nb-card>
	</div>
	<!-- E:ADD PRODUCT -->
</div>

