import { of as observableOf, Observable }                 from 'rxjs';
import { Injectable }                                     from '@angular/core';
import { OrdersChart, OrdersChartData }                   from '../data/orders-chart';
import { OrderProfitChartSummary, OrdersProfitChartData } from '../data/orders-profit-chart';
import { ProfitChart, ProfitChartData }                   from '../data/profit-chart';

@Injectable()
export class OrdersProfitChartService extends OrdersProfitChartData {
    
    private summary = [
        {
            title: '1 Aylık Toplam Sipariş',
            value: 0,
        },
        {
            title: '1 Aylık Toplam Tutar ',
            value: 946,
        },
        {
            title: '1 Aylık Kazanılan Kullanıcı',
            value: 654,
        },
        {
            title: 'Bugün Verilen Sipariş Sayısı',
            value: 230,
        },
    ];
    
    constructor(private ordersChartService: OrdersChartData,
                private profitChartService: ProfitChartData,
    ) {
        super();
        
    }
    
    getOrderProfitChartSummary(): Observable<OrderProfitChartSummary[]> {
        return observableOf(this.summary);
    }
    
    getOrdersChartData(period: string): Observable<OrdersChart> {
        return observableOf(this.ordersChartService.getOrdersChartData(period));
    }
    
    getProfitChartData(period: string): Observable<ProfitChart> {
        return observableOf(this.profitChartService.getProfitChartData(period));
    }
    
}
