import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable }             from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { getEndpoint }            from '../../../environments/environment';
import { IResponseBody }               from '../../@theme/http.interface';
import { IAdmin, IAdminLoginResponse } from '../../@theme/interfaces/admin.interface';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private http: HttpClient, private readonly permissionService: NgxPermissionsService) { }

    public login(body: any): Observable<IResponseBody<IAdminLoginResponse>>{
        return this.http
            .post<IResponseBody<IAdminLoginResponse>>(getEndpoint('/admin/login'), body,
                { observe: 'body'}
            )
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => throwError(error.error))
            )
    }

    public logout(): void {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('apiKey');
    }

    public check(): Observable<IResponseBody<IAdmin>> {
        return this.http
            .get<IResponseBody<IAdmin>>(getEndpoint('/admin/check'),
                {observe: 'body'}
            )
            .pipe(
                retry(1),
                tap((response) => {
                    this.permissionService.loadPermissions(response.data.permissions);
                } ),
                catchError((error:HttpErrorResponse) => throwError(error.error))
            )
    }

    public forgotPassword(body: any): Observable<any>{
        return this.http
            .post<any>(getEndpoint('/admin/forgotPassword'), body,
                { observe: 'body'}
            )
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => throwError(error.error))
            )
    }

    public resetPassword(body: any): Observable<any>{
        return this.http
            .post<any>(getEndpoint('/admin/resetPassword'), body,
                { observe: 'body'}
            )
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => throwError(error.error))
            )
    }
}
