import { HttpErrorResponse }                                   from '@angular/common/http';
import { Component, OnInit }                                   from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService }                                        from '../../@core/utils/login.service';
import { IResponseBody }                                       from '../../@theme/http.interface';
import { NbToastrService } from '@nebular/theme';


@Component({
    selector   : 'ngx-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls  : [ './reset-password.component.scss' ]
})
export class ResetPasswordComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private toastrService: NbToastrService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.fnCreateLoginForm();
        const token = this.route.snapshot.queryParamMap.get('token')
        localStorage.setItem('accessToken', token);
    }

    public fnCreateLoginForm() {
        this.form = this.formBuilder.group({
            newPassword: [ '', [Validators.required, Validators.minLength(6)] ]
        });
    }

    public fnGetFormControl(controlName: string[]): AbstractControl {
        return this.form.get(controlName) as AbstractControl;
    }

    public fnGetFormControlErrors(controlName: string[], errorName: string): boolean {
        const formControl: AbstractControl = this.fnGetFormControl(controlName);
        if(!controlName) {
            return false;
        }
        return formControl.hasError(errorName) && (formControl.dirty || formControl.touched);
    }

    public fnClickSubmitButton() {
        if(this.form.invalid) return;

        this.loginService.resetPassword(this.form.value)
            .subscribe((response: IResponseBody<any>) => {
                    this.toastrService.success(
                        'Your password has been changed successfully. Please login',
                        'Reset Password',
                    );
                    this.router.navigate(['/auth/login']);
                },
                (error: HttpErrorResponse) => {
                    this.toastrService.danger( error.message,'Fail');
                }
            )
    }

}
