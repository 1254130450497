import { Component, OnDestroy, OnInit }                                               from '@angular/core';
import { Router }                                                                     from '@angular/router';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData }               from '../../../@core/data/users';
import { LayoutService }          from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject }                from 'rxjs';
import { LoginService }           from '../../../@core/utils/login.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  currentTheme = 'default';

  userMenu = [ { title: 'Profile', event: 'profile' }, { title: 'Log out', event: 'logout' } ];

  constructor(private sidebarService: NbSidebarService,
              private router: Router,
              private loginService: LoginService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    
      this.menuService.onItemClick()
          .pipe(filter(({ tag }) => tag === 'admin-context-menu'))
          .subscribe((elem: any) => {
              switch(elem.item.event) {
                  case 'logout':
                      this.loginService.logout();
                      this.router.navigate(['/auth/login']);
                      break;
              }
          });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
