import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable }                    from '@angular/core';
import { Observable, throwError }        from 'rxjs';
import { retry, map, catchError }        from 'rxjs/operators';
import { getEndpoint }                   from '../../../environments/environment';
import { IResponseBody }   from '../../@theme/http.interface';
import { IUploadResponse } from '../../@theme/interfaces/article.interface';

@Injectable({
    providedIn: 'root'
})
export class MediaUplaodService {
    
    constructor(private http: HttpClient) { }
    
    public upload(file: any): Observable<Observable<IResponseBody<IUploadResponse>>> {
        const ext: string = file.name.split('.').pop();
        const body: any = {
            fileNameWithExtension: Math.floor((Math.random() + 1) * 1E6) + '.' + ext
        };
        return this.http.post<IResponseBody<IUploadResponse>>(getEndpoint('/utils/generateMediaUploadLink'), body).pipe(
            retry(1),
            map((response: IResponseBody<IUploadResponse>) => {
                /*no-access-control-allow-origin-error */
                return this.http.put<IResponseBody<IUploadResponse>>(
                    response.data.uploadLink,
                    file
                ).pipe(map(() => response))
            }),
            catchError((error: HttpErrorResponse) => throwError(error.error))
        );
    }
}
