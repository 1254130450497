import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule }                           from '@angular/core';
import {
    NbAuthComponent,
    NbLoginComponent,
    NbLogoutComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
}                                             from '@nebular/auth';
import { AuthGuard }                          from './@core/utils/auth.guard';
import { LoginComponent }                     from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

export const routes: Routes = [
    {
        path        : 'pages',
        canActivate : [AuthGuard],
        loadChildren: () => import('./pages/pages.module')
            .then(m => m.PagesModule),
    },
    {
        path     : 'auth',
        component: NbAuthComponent,
        children : [
            {
                path     : '',
                component: LoginComponent,
            },
            {
                path     : 'login',
                component: LoginComponent,
            },
            {
                path     : 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path     : 'reset-password',
                component: ResetPasswordComponent,
            },
        ],
    },
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    imports: [ RouterModule.forRoot(routes, config) ],
    exports: [ RouterModule ],
})
export class AppRoutingModule {
}
