<div class="header-container">
	<div class="logo-container">
		<a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
			<nb-icon icon="menu-2-outline"></nb-icon>
		</a>
		<div style="width: 100%; display: flex; justify-content: center">
            <a href="#" (click)="navigateHome()">
                <img style="width: 100%; height: 40px" class="" src="../../../assets/images/logo.svg" alt="Kevin Jubbal logo">
            </a>
        </div>
	</div>
</div>

<!--<div class="header-container">-->
<!--	<nb-actions size="small">-->
<!--	</nb-actions>-->
<!--</div>-->
