import { HttpErrorResponse }                                   from '@angular/common/http';
import { Component, OnInit }                                   from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router }                                              from '@angular/router';
import { LoginService }                                        from '../../@core/utils/login.service';
import { IResponseBody }                                       from '../../@theme/http.interface';
import { IAdmin, IAdminLoginResponse }                         from '../../@theme/interfaces/admin.interface';


@Component({
    selector   : 'ngx-login',
    templateUrl: './login.component.html',
    styleUrls  : [ './login.component.scss' ]
})
export class LoginComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.fnCreateLoginForm();
        this.loginService.logout();
    }

    public fnCreateLoginForm() {
        this.form = this.formBuilder.group({
            email   : [ '', Validators.required ],
            password: [ '', Validators.required ],
        });
    }

    public fnGetFormControl(controlName: string[]): AbstractControl {
        return this.form.get(controlName) as AbstractControl;
    }

    public fnGetFormControlErrors(controlName: string[], errorName: string): boolean {
        const formControl: AbstractControl = this.fnGetFormControl(controlName);
        if(!controlName) {
            return false;
        }
        return formControl.hasError(errorName) && (formControl.dirty || formControl.touched);
    }

    public fnClickSubmitButton() {
        if(this.form.invalid) {
            return;
        }
        this.loginService.login(this.form.value)
            .subscribe((response: IResponseBody<IAdminLoginResponse>) => {
                    localStorage.setItem('user', JSON.stringify(response.data.admin));
                    localStorage.setItem('accessToken', response.data.token);
                    this.router.navigate([ '/pages/article-list' ]);
                },
                (error: HttpErrorResponse) => {
                    alert('Login Failed!');
                }
            )
    }

}
