<div class="title mb-5">
	<img class="logo-img" src="../../../assets/images/logo.svg" alt="Kevin Jubbal logo">
</div>

<div class="row" [formGroup]="form">
	<!-- ADD PRODUCT -->
	<div class="col-md-12">
		<nb-card>
			<nb-card-body class="example-items-col">
				<!-- MAIL -->
				<input
					type="text"
					nbInput fullWidth placeholder="New password"
					class="form-control"
					id="E-mail"
					formControlName="newPassword">
				<span class="text-danger"
					  *ngIf="fnGetFormControlErrors(['newPassword'], 'required')">
                      Password cannot be left blank!
                </span>
                <span class="text-danger"
                      *ngIf="fnGetFormControlErrors(['newPassword'], 'minLength')">
                      Password must be at least 6 characters!
                </span>
				<!-- E:MAIL -->

				<button nbButton
						fullWidth
						size="medium"
						status="info"
						[disabled]="!form.valid"
						(click)="fnClickSubmitButton()">
					Reset Password
				</button>

			</nb-card-body>
		</nb-card>
	</div>
	<!-- E:ADD PRODUCT -->
</div>

